/**
 * @typedef {Object} Image
 * @property {string} url
 * @property {number} height
 * @property {number} width
 */

/**
 * @typedef {Object} PortraitImages
 * @property {string} fieldId
 * @property {Image[]} fronts
 * @property {Image} back
 */

/**
 * @typedef {Object} Url
 * @property {string} fieldId
 * @property {string} url
 */

/**
 * @typedef {Object} SnsLink
 * @property {string} fieldId
 * @property {string[]} type
 * @property {string} url
 */

/**
 * @typedef {Object} ModelDetail
 * @property {string} id
 * @property {string} clientNameJa
 * @property {string} clientNameEn
 * @property {PortraitImages} [portrateImgs]
 * @property {Url[]} [youTubeUrls]
 * @property {SnsLink[]} [snsLinks]
 * @property {string} illustrator
 * @property {string} rigger
 * @property {Image} [bgPanelImg]
 */

/**
 * @typedef {Object} ApiResponse
 * @property {ModelDetail[]} contents
 * @property {number} totalCount
 * @property {number} offset
 * @property {number} limit
 */

// microCMSのworksAPIからもらうので必要
import { createClient } from 'microcms-js-sdk'

/**
 * @returns {ModelDetail[]}
 */
export async function loadProducedModelDefines() {
    const client = createClient({
        serviceDomain: process.env.VUE_APP_MICROCMS_DOMAIN,
        apiKey: process.env.VUE_APP_MICROCMS_API_KEY,
    });

    // ApiResponseが返ってくる
    return (await client.get({
        endpoint: "works",
        queries: { limit: 100, }
    })).contents
}

/**
 * @param {string} id
 * @returns {ModelDetail}
 */
export async function loadProducedModelDefineById(id) {
    if (!id) {
        throw new Error("モデルのIDが指定されていません");
    }

    const client = createClient({
        serviceDomain: process.env.VUE_APP_MICROCMS_DOMAIN,
        apiKey: process.env.VUE_APP_MICROCMS_API_KEY,
    });

    // ModelDetailが返ってくる
    return (await client.get({ endpoint: "works", contentId: id }));
}
